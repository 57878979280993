.header {
  background-color: #ffffff;
  min-height: 7vh;
  display: flex;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  flex-direction: row;
  align-items: center;
  padding: 1vh 5vh 1vh 5vh;
  font-family: Montserrat;
  justify-content: space-between;
}

.header-logo {
  display: flex;
  justify-content: flex-start;
  width: 120px;
}

.header-button {
  color: var(--light-secondary-color);
  text-transform: none !important;
  font-family: Montserrat !important;
}

.header-button:hover {
  background-color: var(--light-primary-color) !important;
  opacity: 100;
  color: #ffffff;
}

.menu {
  width: 70%;
  float: right;
  display: flex;
  justify-content: flex-end;
}

.menu ul {
  font-size: 15px;
  justify-content: space-evenly;
  width: 100%;
  list-style: none;
  cursor: pointer;
  height: inherit;
  margin: 0;
  padding: 0;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

.menu ul a {
  color: var(--light-primary-color);
  text-decoration: none;
  padding: 5px;
  font-weight: 700;
}

.menu ul a:hover {
  color: var(--light-secondary-color);
  background-color: var(--light-primary-color);
  border-radius: 3px;
  padding: 5px;
}

#menuToggle {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 768px) {
  .header {
    align-items: baseline;
  }

  .menu {
    width: 100%;
    height: auto;
    display: grid;
    z-index: 1;
  }
  .menu ul {
    display: block;
    max-height: 0;
    min-width: 200px;
    position: absolute;
    justify-content: flex-end;
    overflow: hidden;
    text-align: end;
    right: 5%;
    top: 5%;
    width: auto;
    background-color: var(--light-primary-color);
    color: var(--light-secondary-color);
    -webkit-transition: max-height 0.3s;
    -moz-transition: max-height 0.3s;
    -ms-transition: max-height 0.3s;
    -o-transition: max-height 0.3s;
    transition: max-height 0.3s;
  }
  .menu ul a {
    width: 100%;
    height: 50px;
    color: var(--light-secondary-color);
  }

  .menu ul li {
    position: relative;
    padding: 20px 0px 20px 5%;
  }
  .menu ul a:hover {
    width: 100%;
    height: 50px;
    background-color: unset;
  }
  .menu-icon {
    width: auto;
    height: inherit;
    display: block;
    line-height: 60px;
    cursor: pointer;
  }

  #menuToggle:checked ~ ul {
    max-height: 350px;

  }

  .menu-icon span {
    font-size: 2.5em;
  }
}
