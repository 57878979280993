@import url(https://fonts.googleapis.com/css2?family=Fredoka+One&family=Montserrat:wght@400;700&family=Roboto:wght@300;400;700&display=swap);

body {
    height: 100%;
    position: absolute;
    width: 100%;
}

.CategoryList {
    min-height: 100%;
    display: grid;

}
  
 
.header {
  background-color: #ffffff;
  min-height: 7vh;
  display: flex; /* OLD - iOS 6-, Safari 3.1-6 */ /* OLD - Firefox 19- (buggy but mostly works) */ /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  flex-direction: row;
  align-items: center;
  padding: 1vh 5vh 1vh 5vh;
  font-family: Montserrat;
  justify-content: space-between;
}

.header-logo {
  display: flex;
  justify-content: flex-start;
  width: 120px;
}

.header-button {
  color: var(--light-secondary-color);
  text-transform: none !important;
  font-family: Montserrat !important;
}

.header-button:hover {
  background-color: var(--light-primary-color) !important;
  opacity: 100;
  color: #ffffff;
}

.menu {
  width: 70%;
  float: right;
  display: flex;
  justify-content: flex-end;
}

.menu ul {
  font-size: 15px;
  justify-content: space-evenly;
  width: 100%;
  list-style: none;
  cursor: pointer;
  height: inherit;
  margin: 0;
  padding: 0; /* OLD - iOS 6-, Safari 3.1-6 */ /* OLD - Firefox 19- (buggy but mostly works) */ /* TWEENER - IE 10 */ /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

.menu ul a {
  color: var(--light-primary-color);
  text-decoration: none;
  padding: 5px;
  font-weight: 700;
}

.menu ul a:hover {
  color: var(--light-secondary-color);
  background-color: var(--light-primary-color);
  border-radius: 3px;
  padding: 5px;
}

#menuToggle {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 768px) {
  .header {
    align-items: baseline;
  }

  .menu {
    width: 100%;
    height: auto;
    display: grid;
    z-index: 1;
  }
  .menu ul {
    display: block;
    max-height: 0;
    min-width: 200px;
    position: absolute;
    justify-content: flex-end;
    overflow: hidden;
    text-align: end;
    right: 5%;
    top: 5%;
    width: auto;
    background-color: var(--light-primary-color);
    color: var(--light-secondary-color);
    transition: max-height 0.3s;
  }
  .menu ul a {
    width: 100%;
    height: 50px;
    color: var(--light-secondary-color);
  }

  .menu ul li {
    position: relative;
    padding: 20px 0px 20px 5%;
  }
  .menu ul a:hover {
    width: 100%;
    height: 50px;
    background-color: unset;
  }
  .menu-icon {
    width: auto;
    height: inherit;
    display: block;
    line-height: 60px;
    cursor: pointer;
  }

  #menuToggle:checked ~ ul {
    max-height: 350px;

  }

  .menu-icon span {
    font-size: 2.5em;
  }
}


.home-footer {
  margin-top: 50px;
  background-color: var(--light-primary-color);
  padding: 50px;
  text-align: center;
}

:root {
  --light-primary-color: #7f25d9;
  --light-secondary-color: #64fcd9;
  --light-third-color: #ffb61d;
}
h1 {
  font-family: Montserrat;
  font-size: 35px;
  margin: 10px 0 10px 0;
}

h2 {
  font-family: Montserrat;
  font-size: 25px;
  margin: 10px 0 10px 0;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 50px 0 50px;
}

.hero-left {
  width: 50%;
  position: relative;
  max-width: 300px;
}

.hero-left h1 {
  font-family: "Fredoka One", cursive;
  color: var(--light-primary-color);
  font-weight: 400;
  text-align: left;
  font-size: 50px;
}

.hero-right {
  border: 4px solid var(--light-primary-color);
  border-radius: 5px;
  padding: 20px 20px 45px 20px;
}
.hero-right div {
  font-family: Montserrat !important;
}
.hero-right label {
  font-family: Montserrat !important;
}
.hero-right input {
  font-family: Montserrat !important;
}

.hero-right h2 {
  font-family: Montserrat;
  color: var(--light-primary-color);
  text-align: left;
  font-weight: 700;
}

.hero-right div {
  margin-bottom: 4px;
}

.hero-right-2 {
  width: 50%;
  position: relative;
  padding: 20px 20px 45px 20px;
}

.hero-left h2 {
  color: var(--light-primary-color);
  text-align: left;
  font-weight: 700;
}

.submit-button {
  font-family: Montserrat;
  color: var(--light-secondary-color);
  text-transform: none !important;
  font-family: Montserrat !important;
}

.hero-right .hero-password {
  margin-right: 8px;
}

@media screen and (max-width: 768px) {
  .hero {
    display: block;
    justify-content: center;
    align-items: center;
    margin: 0 50px 0 50px;
  }
  .hero-left {
    width: 100%;
    position: relative;
    max-width: unset;
  }
  .hero-left h1 {
    font-family: "Fredoka One", cursive;
    color: var(--light-primary-color);
    font-weight: 400;
    text-align: center;
    font-size: 50px;
  }
  

  .hero-right-2 {
     width: 100%;
     text-align: center;
    position: relative;
    padding: unset;
  }
  
  .hero-image{
      width: 100%;
      max-width: 450px;
  }
}



.comment-submit-button {
    font-family: Montserrat;
    text-transform: none !important;
    font-family: Montserrat !important;
    background-color: var(--light-primary-color) !important ;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    display: block !important;
}



#root{
    height: 100%;
    position: absolute;
    width: 100%;
}
.vetClass{
    min-height: 100%;
    display: grid;

}
  
 
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

