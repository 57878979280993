.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 50px 0 50px;
}

.hero-left {
  width: 50%;
  position: relative;
  max-width: 300px;
}

.hero-left h1 {
  font-family: "Fredoka One", cursive;
  color: var(--light-primary-color);
  font-weight: 400;
  text-align: left;
  font-size: 50px;
}

.hero-right {
  border: 4px solid var(--light-primary-color);
  border-radius: 5px;
  padding: 20px 20px 45px 20px;
}
.hero-right div {
  font-family: Montserrat !important;
}
.hero-right label {
  font-family: Montserrat !important;
}
.hero-right input {
  font-family: Montserrat !important;
}

.hero-right h2 {
  font-family: Montserrat;
  color: var(--light-primary-color);
  text-align: left;
  font-weight: 700;
}

.hero-right div {
  margin-bottom: 4px;
}

.hero-right-2 {
  width: 50%;
  position: relative;
  padding: 20px 20px 45px 20px;
}

.hero-left h2 {
  color: var(--light-primary-color);
  text-align: left;
  font-weight: 700;
}

.submit-button {
  font-family: Montserrat;
  color: var(--light-secondary-color);
  text-transform: none !important;
  font-family: Montserrat !important;
}

.hero-right .hero-password {
  margin-right: 8px;
}

@media screen and (max-width: 768px) {
  .hero {
    display: block;
    justify-content: center;
    align-items: center;
    margin: 0 50px 0 50px;
  }
  .hero-left {
    width: 100%;
    position: relative;
    max-width: unset;
  }
  .hero-left h1 {
    font-family: "Fredoka One", cursive;
    color: var(--light-primary-color);
    font-weight: 400;
    text-align: center;
    font-size: 50px;
  }
  

  .hero-right-2 {
     width: 100%;
     text-align: center;
    position: relative;
    padding: unset;
  }
  
  .hero-image{
      width: 100%;
      max-width: 450px;
  }
}
