
body {
    height: 100%;
    position: absolute;
    width: 100%;
}

.CategoryList {
    min-height: 100%;
    display: grid;

}
  
 