

.comment-submit-button {
    font-family: Montserrat;
    text-transform: none !important;
    font-family: Montserrat !important;
    background-color: var(--light-primary-color) !important ;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    display: block !important;
}



#root{
    height: 100%;
    position: absolute;
    width: 100%;
}
.vetClass{
    min-height: 100%;
    display: grid;

}
  
 